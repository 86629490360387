import React from "react";
import './b1_race_detail.css'
import LayoutB1 from "../../components/layout-b1"


export default class B1NoticeDetail extends React.Component{
  render() {
    const desc=[{type:'text',content:'1、体育成立于200年，总部位于广州CBD一一珠江新城，现在南京、武汉、广州有三家分公司。经过0年的精图治，目前已发属为集策划执行、市场开发为一体的大型体育运营公司，拥有员工近百人十年来博润体育成功运营众多大型赛事，遍布全国'},
      {type:'text',content:'2、理念\n'  + '体育将在体育+旅游”战略规划下，坚持通进举办大型体育赛事促进坡市发展。使命：服务社会成就人生\n' +
          '\n' +
          '愿景：成为专业化、规模化和国际化的体育运营公司\n' +
          '\n' +'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+
          '与各地方政府、知名企业强强联手\n' +
          '\n' +'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+
          '用专业建立地级市大型赛事运营领头标准\n' +
          '\n' +'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+
          '以国际4A广告公关创意标准树立赛事品牌标准\n' +
          '\n' +'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+
          '图烧地方自然风景/人文风情/全民健身发展做赛事整体规划\n' +
          '\n' +'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+
          '将推动群众体育、广泛参与，大型赛事影响力，紧密带动工商界和经济结构发展\n' +
          '\n' +'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+
          '打造策划执行、严密组织、广泛传播、独具特色的优秀体育赛事\n' +
          '\n' +'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+'&nbsp;'+
          '望造城市形象金箔名片'},
      {type:'text',content:'3、核心价值观\n' +
          '精进、创新、共嬴、感恩'},
      {type:'img',src:'//stor.ihuipao.com/image/13b35f7c9c1ae256b50b9e1dfc975615.png?imageView2/2/w/1000/q/100'}]
    const aboutUsDesc=desc.map((content,index)=><div key={index} className="content-item" >
      {content.type==='text'&&<div dangerouslySetInnerHTML={{__html:content.content}}></div>}
      {content.type==='img'&&<img src={content.src} alt="img"/>}
    </div>)
    return(
      <LayoutB1 location={this.props.location}>
        <div className="enroll-detail">
          <div className="desc" >
            {aboutUsDesc}
          </div>
        </div>
      </LayoutB1>
    )
  }
}